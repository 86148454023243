import { Toast,Dialog  } from 'vant';
import axios, { AxiosRequestConfig, AxiosResponse } from "axios"
import { getCurrencyToken } from '@/utils/auth';
const remoteHost =  window.location.hostname
// axios.defaults.baseURL = 'https://switcher.test.100mix.cn/api' //导播台测试环境接口域名

interface RequestConfig extends AxiosRequestConfig {
    headers?: any
}
axios.interceptors.request.use((config: RequestConfig) => {
    if(config.url?.indexOf('/switcher/') !== -1){ // 导播台API
        config.headers['Authorization'] = `Bearer ${ getCurrencyToken() }`
    }else{
        config.headers['Authorization'] = `${ getCurrencyToken() }`
    }
    // config.headers['Authorization'] = `4288a2f9-e78c-4ac0-89a0-a381cd358c76`
    config.headers["Remote-Host"] = window.localStorage.getItem('companyInfoUrl');
    
   
    
    return config
})

axios.interceptors.response.use((response: AxiosResponse) => {
    if (response.data.code != 200) {
        if(response.data.code!=402&&response.data.code!=544&&response.data.code!=405&&response.data.code!=547&&response.data.code!=70006){
            // Toast({message:response.data.msg,duration:3000}) 
            Dialog({ message: response.data.msg });
        }
        if(response.data.code==210){
            Toast.clear();
            WeiXin.miniProgram.reLaunch({
                url: `/pages/login/wxLogin/wxLogin`
            })
        }
        return Promise.reject({requestUrl: response.config.url ,...response.data});
    }
    else return response.data
}, err => {
    console.log('err',err)
    if(err.message=='项目未开始或已停用'){return}
    Toast({message:err.message || '接口请求失败',duration:3000})
    
    return Promise.reject(err)
})


export default axios

import axios from "./request";
import * as IAPiInterface from '@/typings/api'
import { baseUrl, switcherUrl } from '@/config'

const basePath = baseUrl+'/applet/collect-applet-api'
const Gateway = baseUrl+'/collect/collect-video-api'
const GateCom = baseUrl+'/collect/collect-comment-api'
const GateLive = baseUrl+'/collect/collect-live-api'
const Gateway_NTK =baseUrl+ '/collect/collect-company-api-ntk'
const videoPath =  baseUrl+'/collect-company/collect-video-api'
const company = baseUrl+'/collect-company/collect-cms-api'
const livePath = baseUrl+'/collect-company/collect-live-api'
const GateInteractionLive =baseUrl+'/collect/collect-interaction-api'
const GateWatch =baseUrl+'/collect/collect-interaction-api'

const Api:any= {
    GET_VIDEO_LIST : `${Gateway}/video/front/getVideoList` as any, // 获取视频列表
    GET_VIDEO_CODEINFO : `${Gateway}/video/background/getVideoInfo/` as any, // 获取视频详情邀请函用
    GET_VIDEO_INFO : `${Gateway}/video/front/getVideoInfo/` as any, // 获取视频详情
    GET_HOME_VIDEO : `${Gateway}/video/front/homePage/data` as any, // 首页推荐点播
    GET_COM_LIST : `${GateCom}/comment/front/listComments` as any, //评论列表
    GET_COM_PUBLISH : `${GateCom}/comment/publish` as any, //发表评论
    GET_VIDEO_WATCH : `${Gateway}/video/front/getVideoWatchedData/` as any, //获取点播观看数据
    GET_LIVE_LIST : `${GateLive}/live-video/list-by-category` as any, //获取直播列表
    GET_LIVE_INFO : `${GateLive}/live-video/detail-all/` as any, //获取直播详情
    GET_HOST_LIVE : `${GateLive}/live-video/list-home-page` as any, //获取首页直播列表
    GET_LIVE_WATCH : `${GateLive}/live-video/watch-data/` as any, //获取直播观看数据
    GET_LIVE_INFO_SIMPLE : `${GateLive}/live-video/simple-info/` as any, //获取直播观看数据
    GET_LIVE_RECORD : `${GateLive}/live-video/record/watch-data/` as any, //获取直播观看记录

        // 互动直播接口
    GET_LIVE_LIST_NEW : `${GateInteractionLive}/live-interaction/live-page` as any, //获取直播列表
    GET_LIVE_INFO_NEW : `${GateInteractionLive}/live-interaction/detail-all/` as any, //获取直播详情
    GET_HOST_LIVE_NEW : `${GateInteractionLive}/live-interaction/live-home-page` as any, //获取首页直播列表
    GET_LIVE_WATCH_NEW : `${GateInteractionLive}/live-interaction/watch-data/` as any, //获取直播观看数据
    GET_LIVE_INFO_SIMPLE_NEW : `${GateInteractionLive}/live-interaction/simple-info/` as any, //获取直播观看数据
    GET_SPEAKER_INFO_NEW : `${GateInteractionLive}/live-interaction-config/speaker-guest-full` as any, //获取直播观看数据

}

//视频观看位置查询
export function getPosition(data: any) {
    return axios({
        url: basePath + `/project/video/position`,
        method: 'POST',
        data
    })
}

export const getInteractionLiveInfo = (params: any) => {
    return axios({
        method: 'GET',
        url: `${GateInteractionLive}/live-interaction/detail-all-no-check-password`,
        params,
    })
};


export const getHDSimpleInfo = (id: any) => {
    return axios({
        method: 'GET',
        url: `${GateInteractionLive}/live-interaction/simple-info/${id}`,
    })
};

export const getHDWatchedData = (id: any) => {
    return axios({
        method: 'GET',
        url: `${GateInteractionLive}/live-interaction/watch-data/${id}`,
    })
};

export const getspeaker = (params: any) => {
    return axios({
        method: 'GET',
        url: `${GateInteractionLive}/live-interaction-config/speaker-guest-full`,
        params
    })
};

// 弃 -- 换取通用token
export function getAccessToken(data = {
    grant_type: "client_credentials",
    client_id: "yb_collect_toutiao_applet",
    client_secret: "yb_collect_toutiao_applet_123456"
}) {
    return axios({
        url: 'https://mg.test.100api.cn/ybmid/middle-auth/login/oauth2/token',
        method: 'POST',
        data
    })
}

// ============== 表单 & 病例征集相关API ==============
export const getComPublish = (data: any) => {
    return axios({
        method: 'POST',
        url: `${basePath}/project/comment/applet/publish`,
        data,
    })
};   //获取周期表单

export const getComList = (data: any) => {
    return axios({
        method: 'POST',
        url: `${GateCom}/comment/front/listComments`,
        data,
    })
};   //提交周期表单

export const getLiveSimpleInfo = (params: any) => {
    return axios({
        method: 'GET',
        url: `${GateLive}/live-video/simple-info/${params.id}`,
        params,
    })
};

export const getLiveWatchedData = (params: any) => {
    return axios({
        method: 'GET',
        url: `${GateLive}/live-video/watch-data/${params.id}`,
        params,
    })
};   //提交周期表单

export const getVideoWatchedData = (params: any) => {
    return axios({
        method: 'GET',
        url: `${Gateway}/video/front/getVideoWatchedData/${params.id}`,
        params,
    })
};
//获取视频详情

export const getVideoInfo = (params: any) => {
    return axios({
        method: 'GET',
        url: `${videoPath}/video/front/getVideoInfo-no-check-password/${params.id}`,
        params,
    })
};
//获取详情

export const getLiveInfo = (params: any) => {
    return axios({
        method: 'GET',
        url: `${livePath}/live-video/detail-all-no-check-password`,
        params,
    })
};



export const getCompanyConfig = () => {
    return axios({
        method: 'GET',
        url: `${Gateway_NTK}/company/info`
    })
};


//视频观看数据打点
export function seeVideoWatchPointAdd(params: any) {
    return axios({
        url: basePath + `/project/watch/point/add`,
        method: 'POST',
        data: params
    })
}

//无感登录（获取h5的token)
export function getSeeVideoUserLogin(params: any) {
    return axios({
        url: basePath + `/project/user/insen-login`,
        method: 'GET',
        params
    })
}

